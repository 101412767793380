import { OptionProps, localizePlural } from './localize-plural';

export type AvailableModels = 'api_key'
  | 'document'
  | 'document_error'
  | 'document_field_error'
  | 'document_section'
  | 'document_tasks'
  | 'document_type'
  | 'document_type_field'
  | 'document_type_fieldset'
  | 'extraction_type'
  | 'log'
  | 'profile'
  | 'record'
  | 'target'
  | 'target_version'
  | 'transformer'
  | 'verifier'
  | 'webhook';

export function translateModelTitles(modelTitle: AvailableModels, quantity: number) {

  const models = {
    api_key: $localize `:@@model_title.api_key:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.api_key_plural:API Keys`, 1: $localize `:@@model_title.api_key_singular:API Key`, other: $localize `:@@model_title.api_key_plural:API Keys` })}:plural:`,
    document: $localize `:@@model_title.document:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.document_plural:Documents`, 1: $localize `:@@model_title.document_singular:Document`, other: $localize `:@@model_title.document_plural:Documents` })}:plural:`,
    document_error: $localize `:@@model_title.document_error:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.document_error_plural:Document Errors`, 1: $localize `:@@model_title.document_error_singular:Document Error`, other: $localize `:@@model_title.document_error_plural:Document Errors` })}:plural:`,
    document_field_error: $localize `:@@model_title.document_field_error:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.document_field_error_plural:Field Errors`, 1: $localize `:@@model_title.document_field_error_singular:Field Error`, other: $localize `:@@model_title.document_field_error_plural:Field Errors` })}:plural:`,
    document_section: $localize `:@@model_title.document_section:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.document_section_plural:Document Sections`, 1: $localize `:@@model_title.document_section_singular:Document Section`, other: $localize `:@@model_title.document_section_plural:Document Sections` })}:plural:`,
    document_tasks: $localize `:@@model_title.document_tasks:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.document_tasks_plural:Document Tasks`, 1: $localize `:@@model_title.document_tasks_singular:Document Task`, other: $localize `:@@model_title.document_tasks_plural:Document Tasks` })}:plural:`,
    document_type: $localize `:@@model_title.document_type:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.document_type_plural:Document Types`, 1: $localize `:@@model_title.document_type_singular:Document Type`, other: $localize `:@@model_title.document_type_plural:Document Types` })}:plural:`,
    document_type_field: $localize `:@@model_title.document_type_field:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.document_type_field_plural:Document Type Fields`, 1: $localize `:@@model_title.document_type_field_singular:Document Type Field`, other: $localize `:@@model_title.document_type_field_plural:Document Type Fields` })}:plural:`,
    document_type_fieldset: $localize `:@@model_title.document_type_fieldset:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.document_type_fieldset_plural:Document Type Fieldsets`, 1: $localize `:@@model_title.document_type_fieldset_singular:Document Type Fieldset`, other: $localize `:@@model_title.document_type_fieldset_plural:Document Type Fieldsets` })}:plural:`,
    extraction_type: $localize `:@@model_title.extraction_type:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.extraction_type_plural:Extractors`, 1: $localize `:@@model_title.extraction_type_singular:Extractor`, other: $localize `:@@model_title.extraction_type_plural:Extractors` })}:plural:`,
    log: $localize `:@@model_title.log:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.log_plural:Logs`, 1: $localize `:@@model_title.log_singular:Log`, other: $localize `:@@model_title.log_plural:Logs` })}:plural:`,
    profile: $localize `:@@model_title.profile:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.profile_plural:Profiles`, 1: $localize `:@@model_title.profile_singular:Profile`, other: $localize `:@@model_title.profile_plural:Profiles` })}:plural:`,
    record: $localize `:@@model_title.record:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.record_plural:Records`, 1: $localize `:@@model_title.record_singular:Record`, other: $localize `:@@model_title.record_plural:Records` })}:plural:`,
    target: $localize `:@@model_title.target:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.target_plural:Targets`, 1: $localize `:@@model_title.target_singular:Target`, other: $localize `:@@model_title.target_plural:Targets` })}:plural:`,
    target_version: $localize `:@@model_title.target_version:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.target_version_plural:Target Versions`, 1: $localize `:@@model_title.target_version_singular:Target Version`, other: $localize `:@@model_title.target_version_plural:Target Versions` })}:plural:`,
    transformer: $localize `:@@model_title.transformer:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.transformer_plural:Transformers`, 1: $localize `:@@model_title.transformer_singular:Transformer`, other: $localize `:@@model_title.transformer_plural:Transformers` })}:plural:`,
    verifier: $localize `:@@model_title.verifier:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.verifier_plural:Verifiers`, 1: $localize `:@@model_title.verifier_singular:Verifier`, other: $localize `:@@model_title.verifier_plural:Verifiers` })}:plural:`,
    webhook: $localize `:@@model_title.webhook:${localizePlural(quantity as OptionProps, { 0: $localize `:@@model_title.webhook_plural:Webhooks`, 1: $localize `:@@model_title.webhook_singular:Webhook`, other: $localize `:@@model_title.webhook_plural:Webhooks` })}:plural:`,
  };

  return models[modelTitle] ?? '';
}
